import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'getData',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'getData/:name',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },{
    path: 'showData',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'showData/:name',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'getOrderPage',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./reports-contacts/reports-contacts.module').then( m => m.ReportsContactsPageModule)
  },
  {
    path: 'getReport',
    loadChildren: () => import('./reports-contacts/reports-contacts.module').then( m => m.ReportsContactsPageModule)
  },
  {
    path: 'getReport?perPage=20',
    loadChildren: () => import('./reports-contacts/reports-contacts.module').then( m => m.ReportsContactsPageModule)
  },
  {
    path: 'getCCIContactList',
    loadChildren: () => import('./reports-contacts/reports-contacts.module').then( m => m.ReportsContactsPageModule)
  },
  {
    path: 'getCCIContactList?perPage=20',
    loadChildren: () => import('./reports-contacts/reports-contacts.module').then( m => m.ReportsContactsPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'mpn-search',
    loadChildren: () => import('./mpn-search/mpn-search.module').then( m => m.MpnSearchPageModule)
  },
  {
    path: 'reports-contacts',
    loadChildren: () => import('./reports-contacts/reports-contacts.module').then( m => m.ReportsContactsPageModule)
  },
  {
    path: 'fems-report',
    loadChildren: () => import('./report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'reports-coin-details',
    loadChildren: () => import('./reports-coin-details/reports-coin-details.module').then( m => m.ReportsCoinDetailsPageModule)
  },
  {
    //path: 'customer-coin-inventory/:memberID',
    path: 'customer-coin-inventory',
    loadChildren: () => import('./customer-coin-inventory/customer-coin-inventory.module').then( m => m.CustomerCoinInventoryPageModule)
  },
  {
    path: 'search-customer',
    loadChildren: () => import('./search-customer/search-customer.module').then( m => m.SearchCustomerPageModule)
  },
  {
    path: 'packing-list',
    loadChildren: () => import('./packing-list/packing-list.module').then( m => m.PackingListPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'user-details',
    loadChildren: () => import('./user-details/user-details.module').then( m => m.UserDetailsPageModule)
  },
  {
    path: 'tracking-details',
    loadChildren: () => import('./tracking-details/tracking-details.module').then( m => m.TrackingDetailsPageModule)
  },
  {
    path: 'showLineData',
    loadChildren: () => import('./line-items/line-items.module').then( m => m.LineItemsPageModule)
  },
  {
    path: 'consigned-report',
    loadChildren: () => import('./consigned-report/consigned-report.module').then( m => m.ConsignedReportPageModule)
  },
  {
    path: 'demo-video',
    loadChildren: () => import('./demo-video/demo-video.module').then( m => m.DemoVideoPageModule)
  },
  {
    path: 'xl-error-messages',
    loadChildren: () => import('./xl-error-messages/xl-error-messages.module').then( m => m.XlErrorMessagesPageModule)
  }






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
